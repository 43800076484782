<template>
  <div class="view pa24">
    <div class="d-flex justify-content-between flex-wrap-y align-items-center">
      <div class="operation-left"></div>
      <div class="operation-right d-flex">
        <el-radio-group class="mr-16 d-flex align-items-center" @change="selectAllPointRecord"
          v-model="searchData.pointRecordType">
          <el-radio-button label="all">全部</el-radio-button>
          <el-radio-button label="0">增加</el-radio-button>
          <el-radio-button label="1">扣除</el-radio-button>
        </el-radio-group>
        <el-input v-model="searchData.pointRecordName" class="mr-16 d-flex align-items-center"
          placeholder="请输入积分描述"></el-input>
        <el-button style="height: 32px" type="primary" @click="selectAllPointRecord">查询</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="nickeName" align="center" label="会员昵称" width="120" show-overflow-tooltip />
        <el-table-column prop="pointRecordName" align="center" label="积分描述" show-overflow-tooltip />
        <el-table-column show-overflow-tooltip prop="pointNum" align="center" label="积分" width="100">
          <template slot-scope="scope">
            <div>
              <div style="color: #67c23a" v-if="scope.row.pointRecordType == 0">
                +{{ scope.row.pointNum }}
              </div>
              <div style="color: #f56c6c" v-if="scope.row.pointRecordType == 1">
                -{{ scope.row.pointNum }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" align="center" label="添加日期">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button class="mr10 btnDel" type="text" @click="del(scope.row)"
              >清除记录</el-button
            >
          </template>
        </el-table-column> -->
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { selectAllPointRecord } from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      tableData: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      searchData: {
        pointRecordType: "all",
        pointRecordName: "",
      },
    };
  },
  filters: {},
  created() {
    this.selectAllPointRecord();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    /**@method 获取列表 */
    async selectAllPointRecord(payload) {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyId: this.userInfo.companyId,
        // companyId: 2,
        ...payload,
      };
      if (this.searchData.pointRecordType != "all") {
        params.pointRecordType = this.searchData.pointRecordType;
      }
      if (this.searchData.pointRecordName) {
        params.pointRecordName = this.searchData.pointRecordName;
      }
      try {
        this.loading = true;
        const result = await selectAllPointRecord(params, 9);
        this.loading = false;

        const { data } = result;
        this.tableData = data.pageInfo.list;
        this.total = data.pageInfo.total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.selectAllPointRecord();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectAllPointRecord();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    del(val) { },
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}

.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }

  .levelPrice {
    width: 200px;
  }

  .levelTime {
    width: 200px;
  }
}

.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}

.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }

  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.btnDel {
  color: #fd634e;
}
</style>